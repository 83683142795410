<template>
  <div class="login-content">
    <div class="title">
      <p>欢迎来到企云巅</p>
    </div>
    <div class="content-form">
      <div class="content-form-ttl-pc">企业用户登录</div>
      <firm-form></firm-form>
      <!-- <div class="way">个人用户登录</div> -->
    </div>
  </div>
</template>
<script>
import FirmForm from './firmForm.vue'
export default {
  name: 'loginContent',
  components: {
    FirmForm
  },
  data() {
    return {}
  },
  created() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
// @media (max-width: 767px) {
.login-content {
  width: calc(100% - 24px);
  background: #fff;
  border-radius: 10px;
  margin: 0 auto;
  .title {
    padding: 33px 0 38px;
    color: $robin-title-color;
    font-size: 21px;
    text-align: center;
  }
  .content-form {
    .content-form-ttl-pc {
      display: none;
    }
    .way {
      color: $robin-essential-color;
      text-align: center;
      padding: 32px 0 62px;
    }
  }
}
// }

@media (min-width: 768px) {
  .login-content {
    display: flex;
    box-shadow: 0px 1px 11px 0px rgba(0, 0, 0, 0.11);
    border-radius: 10px;
    height: 600px;
    margin-top: 36px;
    margin-bottom: 78px;
    .title {
      width: 243px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: url(../../../assets/login-content-ttl-pc.png);
      p {
        position: relative;
        top: -30px;
        font-size: 22px;
        font-weight: bold;
        color: $robin-essential-color;
        &::after {
          position: absolute;
          content: '';
          width: 60px;
          height: 2px;
          background: $robin-essential-color;
          border-radius: 2px;
          bottom: -10px;
          left: 50%;
          margin-left: -30px;
        }
      }
    }
    .content-form {
      width: calc(100% - 243px);
      padding: 55px 125px 0;
      .content-form-ttl-pc {
        display: block;
        font-size: 26px;
        font-weight: 400;
        color: $robin-title-color;
        text-align: center;
        margin-bottom: 46px;
      }
      .way {
        font-size: 18px;
        cursor: pointer;
        padding: 0;
        margin-top: 35px;
      }
    }
  }
}
</style>
